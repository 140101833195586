<template>
  <div>
    <div class="text-center pb-3">
      <img src="@/assets/images/impacts/qualityoflife.png">
    </div>
    <div class="nav-sticky-wraper bg-grey">
      <div class="text-center">
        <h1>
          <router-link class="no-underline text-grey-darker align-middle text-lg" :to="this.previous.url"> ◀ </router-link>
          Quality of Life
          <router-link class="no-underline text-grey-darker align-middle text-lg" :to="this.next.url"> ▶ </router-link>
        </h1>
      </div>
      <div class="section-navigation">
        <div><a class="cursor-pointer" @click="$refs.introduction.scrollIntoView()">Introduction</a></div>
        <div><a class="cursor-pointer" @click="$refs.challenges.scrollIntoView()">Challenges</a></div>
        <div><a class="cursor-pointer" @click="$refs.actions.scrollIntoView()">Actions</a></div>
      </div>
    </div>
    <article>
      <div>
        <a ref="introduction" name="introduction"/>
        <h2>Introduction</h2>

        <h3>Preparing for a new climate</h3>
        <p class="teaser">Climate change is poised to have far-reaching impacts on our homes, health, and way of life.</p>

        <image-caption class="border-solid border-green border-2"
          :image="'impacts/mountain_biking.jpg'"
          title="Case Study"
          caption="Wildfire Smoke Events"
          link="/case-studies/CaseStudy_Smoke&QoL.pdf" />

        <p>Rising temperatures and major changes in rain and snowfall have the potential to affect our homes, lifestyles, and quality of life in both subtle and more catastrophic ways.</p>
        <p>Severe weather events, flood and wildfire can cause obvious impacts over a short period of time. Other climate impacts progress more slowly, like drought conditions, changes to species and ecosystems, and long-term changes to the Basin&#39;s water resources.</p>
        <p>And because many of the changes and impacts have the potential to affect our health and quality of life in the Basin, we must prepare for new climate extremes.</p>

        <learn-more>
          <learn-more-item href="https://ourtrust.org/wp-content/uploads/downloads/2011-7_Trust_AdaptationDiscoveryToolKit_Web.pdf">Adaptation Discovery Toolkit</learn-more-item>
          <learn-more-item href="https://ourtrust.org/wp-content/uploads/downloads/2017-03_Trust_ClimateActionBooklet_Interactive_FINAL.pdf">Climate Action in the Columbia Basin</learn-more-item>
          <learn-more-item href="http://cbrdi.ca/Projects/Climate-Adaptation">State of Climate Adaptation Assessments for Basin communities </learn-more-item>
        </learn-more>
      </div>

      <div>
        <a ref="challenges" name="challenges"/>
        <h2>Challenges</h2>

        <h3>Protecting our homes</h3>
        <p class="teaser">Learn how our homes, gardens, and properties can be affected.</p>

        <img src="@/assets/images/impacts/icicles.jpg" alt="Icicles">

        <p>For many of us, &quot;our home is our castle.&quot; To ensure the continued health, safety, and comfort of our homes in a changing climate we need to understand emerging climate-related risks and challenges.</p>

        <ul>
          <li>Heat: Hotter summers and more extreme heat days could make it harder to keep your home at a reasonable temperature. The need for cooling is expected to increase (check out the map to see how this is expected to change in your community).</li>
          <li>Flood: The average cost of repairing a flooded basement is over $40,000. With climate change, floods could happen more often and/or increase in severity.</li>
          <li>Wildfire: According to the Insurance Bureau of Canada, the average cost of damage to residential properties in the 2016 Fort McMurray fire was $80,000. BC&#39;s wildfire seasons are getting longer and more extreme.</li>
          <li>Severe Storms: ice storms and windstorms can cause major damage from falling trees, downed electrical lines and heavy snowloads. Extreme rainfall can cause flooding and backup of stormwater systems.</li>
          <li>Lawns and gardens: warmer wetter winters, hotter drier summers, and longer growing seasons will have both positive and negative impacts for food-producing and non-food-producing trees and plants, landscaping, and yard care.</li>
          <li>Property value: Real estate markets are showing that properties exposed to a higher risk of flood and/or wildfire may be losing value relative to lower risk properties.</li>
          <li>Change in Cooling Degree Days (the annual sum of daily average temperatures over 18ºC)</li>
        </ul>


        <ul class="layer-list">
          <map-layer :id="11" :viewEnabledDefault="true"/>
        </ul>

        <learn-more>
          <learn-more-item href="https://www.iclr.org/homeowner/">ICLR Protect Your Home series</learn-more-item>
          <learn-more-item href="https://www.climateresilienthome.ca/">Climate resilient home</learn-more-item>
        </learn-more>

        <h3>Health</h3>
        <p class="teaser">From more allergies to accidents to anxiety, climate change will have direct and indirect effects on our health</p>
        <img src="@/assets/images/impacts/glass_tapwater.jpg" alt="Tapwater in a Glass">

        <p>As climate change intensifies, these health-related impacts are expected to become more common:</p>
        <ul>
          <li>accidents/injuries due to extreme weather events and resulting from poor road conditions, falling trees, collapsing structures, etc.</li>
          <li>respiratory challenges due to poor air quality and/or allergens (check out the map to access air quality data for our region)</li>
          <li>allergies and/or intensification of allergy symptoms</li>
          <li>heat-related illnesses and deaths</li>
          <li>food and water contamination and/or shortages</li>
          <li>mental health impacts including anxiety, stress, depression, and trauma.</li>
          <li>vector-borne infectious diseases and water-borne pathogens</li>
        </ul>
        <p>Three years after flooding, almost half (48 per cent) of respondents from flooded households experienced worry when it rained, compared to three percent of respondents from non-flooded households.</p>

        <!-- <ul class="layer-list">
          <map-layer :id="11" :viewEnabledDefault="true"/>
        </ul> -->

        <learn-more>
          <learn-more-item href="https://climatechoices.ca/reports/the-health-costs-of-climate-change/">The Health Costs of Climate Change</learn-more-item>
          <learn-more-item href="https://www.interiorhealth.ca/YourEnvironment/ClimateChange/Pages/default.aspx">Interior Health Authority - Climate Change and Health</learn-more-item>
          <learn-more-item href="https://bcmj.org/cohp/unseen-impacts-climate-change-mental-health">Unseen Impacts of Climate Change on Mental Health</learn-more-item>
          <learn-more-item href="https://retooling.ca/taking-action/health/">Healthy and Resilient Communities</learn-more-item>
          <learn-more-item href="https://www.fnha.ca/what-we-do/environmental-health/climate-health-action-program">Indigenous Climate Action Health Program</learn-more-item>
          <learn-more-item href="https://sustain.ubc.ca/about/resources/programs-and-interventions-address-climate-grief">Programs and interventions to address climate grief</learn-more-item>
        </learn-more>

        <h3>Lifestyles</h3>
        <p class="teaser">Climate change could reshape the way residents live, work, and play in the Basin</p>
        <img src="@/assets/images/impacts/hockey.jpg" alt="Hockey">

        <p>Businesses and residents may already be noticing the effects of climate change on recreational pursuits, cultural practices, and jobs.</p>
        <p>Looking to the future, warmer wetter winters will shorten natural ice and snow sport seasons. In addition to heatwaves, hotter drier summers will increase the likelihood of poor air quality due to wildfires and the prospect of campfire bans and closures of the backcountry due to extreme fire risk. Lower stream flows will affect river recreation activities like fishing and paddling. Extreme weather-related events like flooding and wildfire can cause major disruptions to transportation, infrastructure, and supply chains.</p>
        <p>Cultural practices and traditional lifestyles are also at risk. Climate change could lead to widescale environmental change and affect species movement and behaviour. This has consequences for First Nations cultural practices influenced by traditional knowledge. Heritage assets are also vulnerable to wildfire and significant changes in weather patterns.</p>
        <p>How climate change will impact our work and economy in the Basin is less predictable. Tourism, forestry, agriculture, and energy are among the economic sectors that will experience the effects more directly. At the same time, green jobs and remote work opportunities are increasing.</p>

        <learn-more>
          <learn-more-item href="https://blogs.ubc.ca/michaelpidwirny/2017/11/02/climate-change-challenges-for-alpine-ski-resorts-in-western-canada/">Climate Change Challenges for Alpine Ski Resorts in Western Canada</learn-more-item>
          <learn-more-item href="https://vancouversun.com/news/climate-displacement-a-growing-concern-in-b-c-as-extreme-weather-forces-residents-out-of-their-homes">Climate Displacement a Growing Concern in BC</learn-more-item>
          <learn-more-item href="https://eco.ca/new-reports/environmental-labour-demand-outlook/">Environmental Labour Demand Outlook</learn-more-item>
        </learn-more>
      </div>

      <div v-observe-visibility="{ callback: handleActions, throttle: 500 }">
        <a ref="actions" name="actions"/>
        <h2>Actions</h2>
        <h3>Protecting Your Home</h3>
        <p class="teaser">Discover the important steps residents can take to make their homes more climate-resilient</p>
        <img src="@/assets/images/impacts/xeriscaping.jpg" alt="Xeriscaping">

        <p>Whether it’s the overall safety and integrity of your home, using water more wisely, being prepared for emergencies or producing food in your garden, there’s lots you can do to improve your home and family’s resilience to climate impacts. Check out the links below to learn more and see what’s happening near your community on the climate action map.</p>

        <learn-more>
          <learn-more-item href="https://www.iclr.org/homeowner/">ICLR Protect Your Home series </learn-more-item>
          <learn-more-item href="https://www.climateresilienthome.ca/">Climate resilient home</learn-more-item>
          <learn-more-item href="https://firesmartbc.ca/resource-types/guides-manuals/">FireSmart your Property</learn-more-item>
          <learn-more-item href="https://watershedsentinel.ca/articles/saving-water-in-the-garden-xeriscaping/">Xeriscaping Guide</learn-more-item>
          <learn-more-item href="https://www.intactcentreclimateadaptation.ca/wp-content/uploads/2019/04/English_No-Brand_Three-Steps-to-Basement-Flood-Protection-Infographic_final_No-Brand.pdf">Basement Flood Protection</learn-more-item>
          <learn-more-item href="https://www.climateresilienthome.ca/">Climate resilient home</learn-more-item>
        </learn-more>

        <h3>Protecting your health and building social resilience</h3>
        <p class="teaser">Learn what you can do to reduce the health risks of climate change</p>
        <img src="@/assets/images/impacts/senior.jpg" alt="Seniors">

        <p>The number one action you can take to protect your health is to get informed about the risks of climate change in your area, understand how you and your family and neighbours could be at risk, and take steps to make your homes, communities and workplaces more climate-resilient. Don’t forget that strong community relationships can help support our mental health and increase social resilience to disasters and change.</p>
        <ul>
          <li>Take time to explore the topics and links above.</li>
          <li>Find out what kind of information and resources are available in your community</li>
          <li>Consider vulnerable populations in your community, i.e. seniors, children, low income, and individuals with special needs or chronic illnesses.</li>
          <li>Leading a healthy, active lifestyle can be a key factor for health and quality of life.</li>
        </ul>
        

        <learn-more>
          <learn-more-item href="https://climateatlas.ca/video/heat-waves-and-hope">Heatwaves and Hope</learn-more-item>
          <learn-more-item href="https://www.resilientneighbourhoods.ca/">Resilient Neighbourhoods</learn-more-item>
        </learn-more>

        <h3>Adapting lifestyles</h3>
        <p class="teaser">Climate change will get personal. It’s time to start acting on the solutions</p>

        <img src="@/assets/images/impacts/dear-future.jpg" alt="Dear Future, I'm Ready">

        <p>As climate change intensifies, so does the likelihood that you, your family, neighbours, and workplace will be directly affected. There is no better time to get informed, and understand the ways in which climate change could impact your lifestyle and livelihood. The growing climate emergency means we are all being called to take action in multiple ways, whether that’s reducing greenhouse gases or adapting to a changing climate &mdash; at home, at work, and in your community. It’s our time to act.</p>

        <learn-more>
          <learn-more-item href="https://theconversation.com/5-ways-families-can-help-tackle-climate-change-126512">Five ways families can help tackle climate change</learn-more-item>
          <learn-more-item href="https://www.drawdown.org/climate-solutions-101">Drawdown Climate Solutions 101</learn-more-item>
          <learn-more-item href="https://davidsuzuki.org/what-you-can-do/top-10-ways-can-stop-climate-change/">10 things you can do about climate change</learn-more-item>
        </learn-more>
      </div>
    </article>
  </div>
</template>

<script>
import ImpactMixin from '@/components/ImpactMixin.vue'

const meta = {
  title: 'Quality of Life',
  description: 'Climate change is poised to have far-reaching impacts on our homes, health and way of life.'
}

export default {
  metaInfo () {
    return {
      title: meta.title,
      meta: [
        { property: 'description', content: meta.description, vmid: 'description' },
        { property: 'og:description', content: meta.description, vmid: 'og:description' },
        { property: 'twitter:title', content: meta.title, vmid: 'twitter:title' },
        { property: 'twitter:description', content: meta.description, vmid: 'twitter:description' }
      ]
    }
  },
  mixins: [ImpactMixin]
}
</script>

<style>
</style>
