<template>
  <div class="standard-sidebar">
    <div v-if="!this.currentRoute">

      <div class="bg-grey">
        <div class="text-center pb-4">
          <h1>Impacts</h1>
        </div>
        <article>
          <p>Changes in climate will impact the Columbia Basin-Boundary region in many ways. Our focus areas for ‘impacts’ include the economy, nature, homes, buildings and infrastructure, and quality of life.</p>
          <p>The introduction for each 'impact' area includes a brief case study, followed by climate-related challenges, and suggestions for action. Additional resources can be found by clicking on “Learn More” wherever it appears.</p>
          <p class="teaser mt-2">Click on an impact to learn more...</p>

          <div class="flex flex-wrap justify-between">
            <div class="text-center flex flex-col mb-3 mt-3" :key=index v-for="(link, key, index) in this.possibleRoutes">
              <div >
                <router-link class="flex items-center route" :to="link.url">
                  <img class="link-image" :src="link.image || 'http://via.placeholder.com/100X100'">
                  <div class="link-text ml-2">{{ link.title }}</div>
                </router-link>
              </div>
            </div>
          </div>
        </article>
      </div>

    </div>

    <router-view
      :previous="this.possibleRoutes[this.prevRoute]"
      :next="this.possibleRoutes[this.nextRoute]"
      :current="this.possibleRoutes[this.currentRoute]"
      />

  </div>
</template>

<script>
const meta = {
  title: 'Impacts',
  description: 'Focus areas for "impacts" include the economy, nature, homes, buildings and infrastructure, and quality of life'
}

export default {
  metaInfo () {
    return {
      title: meta.title,
      meta: [
        { property: 'description', content: meta.description, vmid: 'description' },
        { property: 'og:description', content: meta.description, vmid: 'og:description' },
        { property: 'twitter:title', content: meta.title, vmid: 'twitter:title' },
        { property: 'twitter:description', content: meta.description, vmid: 'twitter:description' }
      ]
    }
  },
  data () {
    return {
      currentRoute: this.$route.path.split('/')[2],
      possibleRoutes: {
        economy: {
          title: 'Economy',
          url: '/impacts/economy',
          image: require('@/assets/images/impacts/economy.png')
        },
        nature: {
          title: 'Nature',
          url: '/impacts/nature',
          image: require('@/assets/images/impacts/nature.png')
        },
        infrastructure: {
          title: 'Homes, Buildings & Infrastructure',
          url: '/impacts/infrastructure',
          image: require('@/assets/images/impacts/housing.png')
        },
        'quality-of-life': {
          title: 'Quality of Life',
          url: '/impacts/quality-of-life',
          image: require('@/assets/images/impacts/qualityoflife.png')
        }
      }
    }
  },
  computed: {
    nextRoute: function () {
      const keys = Object.keys(this.possibleRoutes)
      return (keys.indexOf(this.currentRoute) + 1 >= keys.length)
        ? keys[0]
        : keys[keys.indexOf(this.currentRoute) + 1]
    },
    prevRoute: function () {
      const keys = Object.keys(this.possibleRoutes)
      return (keys.indexOf(this.currentRoute) - 1 < 0)
        ? keys[keys.length - 1]
        : keys[keys.indexOf(this.currentRoute) - 1]
    }
  },
  watch: {
    $route: function (newVal) {
      this.currentRoute = newVal.path.split('/')[2]
    }
  }
}
</script>

<style lang="scss" scoped>
.link-image {
  max-width: 75px;
  object-fit: contain;
}

.link-text {
  width: 7rem;
}
</style>
