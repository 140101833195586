<script>
import learnMore from '@/components/learnMore.vue'
import learnMoreItem from '@/components/learnMoreItem.vue'
import MapLayer from '@/components/MapLayer.vue'
import ImageCaption from '@/components/ImageCaption.vue'

export default {
  props: ['previous', 'next', 'current'],
  components: {
    'learn-more': learnMore,
    'learn-more-item': learnMoreItem,
    'map-layer': MapLayer,
    'image-caption': ImageCaption
  },
  methods: {
    handleActions (isVisible/*, entry */) {
      if (isVisible) {
        this.$store.commit('ACTION_IMPACT', this.current.title)
        this.$store.commit('ACTION_LAYER_VISIBILITY', true)
      } else if (!isVisible) {
        this.$store.commit('ACTION_LAYER_VISIBILITY', false)
        this.$store.commit('ACTION_IMPACT', '')
      }
    }
  },
  destroyed () {
    this.$store.commit('ACTION_LAYER_VISIBILITY', false)
    this.$store.commit('ACTION_IMPACT', '')
  }
}
</script>
