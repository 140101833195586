<template>
  <li class="pt-1 pb-1"><a :href="this.href" target="_blank"><slot/></a></li>
</template>

<script>
export default {
  props: ['href']
}
</script>

<style>
</style>
