<template>
  <div>
    <div class="text-center pb-3">
      <img src="@/assets/images/impacts/economy.png">
    </div>
    <div class="nav-sticky-wraper bg-grey">
      <div class="text-center">
        <h1>
          <router-link class="no-underline text-grey-darker align-middle text-lg" :to="this.previous.url"> ◀ </router-link>
          Economy
          <router-link class="no-underline text-grey-darker align-middle text-lg" :to="this.next.url"> ▶ </router-link>
        </h1>
      </div>
      <div class="section-navigation">
        <div><a class="cursor-pointer" @click="$refs.introduction.scrollIntoView()">Introduction</a></div>
        <div><a class="cursor-pointer" @click="$refs.challenges.scrollIntoView()">Challenges</a></div>
        <div><a class="cursor-pointer" @click="$refs.actions.scrollIntoView()">Actions</a></div>
      </div>
    </div>

    <article>
      <div>
        <a ref='introduction' name="introduction"/>
        <h2>Introduction</h2>

        <h3>The economy exists within our environment</h3>
        <p class="teaser">To help assure a resilient economy, we must plan for climate risks and opportunities and transition to a clean, lower emission future.</p>

        <image-caption class="border-solid border-green border-2"
          :image="'impacts/creston-mainst.jpg'"
          title="Case Study"
          caption="Growing Food In A Changing Climate"
          link="/case-studies/CaseStudy_CrestonAgriculture.pdf" />

        <p>There are at least four ways climate change will affect economies and businesses in the Basin:</p>
        <ol>
          <li>create new economic opportunities</li>
          <li>cause direct damage or loss to property, natural resources, assets, and productivity</li>
          <li>drive the transition from a higher emission, fossil-fuels-based economy to a clean, green lower emission economy</li>
          <li>impact costs and/or availability of goods and services</li>
        </ol>


        <learn-more>
          <learn-more-item href="https://cleanbc.gov.bc.ca/">Clean BC</learn-more-item>
          <learn-more-item href="https://www.canada.ca/en/services/environment/weather/climatechange/pan-canadian-framework.html">PanCanadian Framework for Clean Growth and Climate Change</learn-more-item>
          <learn-more-item href="https://climatechoices.ca/reports/sink-or-swim/">Sink or Swim: Transforming Canada’s Economy for a Global Lower emission Future </learn-more-item>
          <learn-more-item href="https://www.canada.ca/en/services/environment/weather/climatechange/climate-plan/climate-plan-overview/healthy-environment-healthy-economy.html">A Healthy Environment and a Healthy Economy</learn-more-item>
        </learn-more>

      </div>
      <div>
        <a ref="challenges" name="challenges"/>
        <h2>Challenges</h2>
        <h3>Emerging economic opportunities</h3>
        <p class="teaser">It’s not all bad news. There could be many winners.</p>
        <img src="@/assets/images/impacts/recharge-ecar.jpg"/>
        <p>
          As climate changes and the world transitions to a lower emission economy, there will be economic sectors that grow and benefit.
        </p>
        <p>
           For example:
          <ul>
            <li>Clean energy (check out the map for Major Clean Energy and Green Building Projects)</li>
            <li>Energy efficiency</li>
            <li>Clean technology</li>
            <li>Agriculture and food production in the Basin (check out the growing season tab in the Climate Layers to see how growing seasons are projected to change in the future)</li>
            <li>Green building and construction materials</li>
            <li>Repair and retrofit services</li>
            <li>Sharing and re-use services</li>
            <li>Lower emission transportation services</li>
          </ul>
        </p>
        <p>An important part of the shift to a lower emission economy is a just transition for the workers and communities currently dependent on high emitting sectors for their livelihoods.</p>

        <ul class="layer-list">
          <map-layer :id="4" :viewEnabledDefault="true"/>
        </ul>

        <learn-more>
          <learn-more-item href="https://cleanbc.gov.bc.ca/">Clean BC</learn-more-item>
          <learn-more-item href="https://www.workbc.ca/Labour-Market-Industry/B-C-s-Economy/Green-Economy.aspx">Work BC - Green Economy</learn-more-item>
          <learn-more-item href="https://www.nrcan.gc.ca/sites/www.nrcan.gc.ca/files/earthsciences/pdf/assess/2016/14-0801-MCR-Access_Apr7_en.pdf">Managing Climate Risks - Highlights for Business Leaders</learn-more-item>
          <learn-more-item href="https://www.rncanengagenrcan.ca/sites/default/files/pictures/home/just_transition_discussion_paper_-_en_-_july_15.pdf">Just Transition</learn-more-item>
          <learn-more-item href="https://climatechoices.ca/reports/clean-growth/">11 Ways to Measure Clean Growth</learn-more-item>
        </learn-more>

        <h3>Business disruption</h3>
        <p class="teaser">When it rains, it can pour. Really pour.</p>
        <img src="@/assets/images/impacts/gf_flood.png"/>
        <p>
          Climate change can change the business landscape in multiple ways. Here are some examples.
        </p>
        <p>
          <ul>
            <li>Extreme events locally like flooding or wildfire can cause business interruption and even permanent business closures.</li>
            <li>Extreme events elsewhere can impact Basin businesses if supply chains are disrupted. Check out the map to see how weather events affect transportation in our region.</li>
            <li>Businesses with a high energy demand will face increasing prices for fuel and growing pressure to find greener alternatives.</li>
          </ul>
        </p>

        <ul class="layer-list">
          <map-layer :id="5" :viewEnabledDefault="true"/>
        </ul>

        <learn-more>
          <learn-more-item href="https://www2.gov.bc.ca/assets/gov/public-safety-and-emergency-services/emergency-preparedness-response-recovery/embc/preparedbc/preparedbc-guides/preparedbc_small_business_guide.pdf">Prepared BC Guide for Small Businesses</learn-more-item>
          <learn-more-item href="https://www.bceda.ca/docs/2020_BCEDA_10_Steps_to_Prepare_your_Business_for_Evacuation.pdf">10 Steps to Prepare your Business for Evacuation</learn-more-item>
          <learn-more-item href="https://www.bceda.ca/docs/2020_BCEDA_Economic_Recovery_Resiliency_Toolkit_FINAL.pdf">Economic Recovery and Resiliency Toolkit</learn-more-item>
          <learn-more-item href="https://www.destinationbc.ca/what-we-do/destination-management/emergency-preparedness/">Emergency Preparedness for BC’s Tourism Industry</learn-more-item>
        </learn-more>

        <h3>Basin business sectors at risk</h3>
        <p class="teaser">Climate change is a concern for Basin forestry, agriculture, tourism, transportation, and hydroelectric power facilities</p>
        <img src="@/assets/images/impacts/dam.jpg"/>

        <p>Businesses that are highly climate-dependent, like ski hills, outdoor recreation, food production and forestry will be most vulnerable to climate change.</p>
        <p>Shifts in the balance between rain and snow in our region, including more winter precipitation falling as rain at lower elevations, are expected to impact outdoor winter recreation opportunities.</p>
        <p>Commercial transportation will be affected by extreme weather events causing highway closures.</p>
        <p>Hydroelectric power generators will have to adapt to changes in seasonal water availability as summers get hotter and drier and winters get warmer and wetter.</p>

        <!-- <ul class="layer-list">
          <map-layer :id="6" :viewEnabledDefault="true"/>
        </ul> -->

        <learn-more>
          <learn-more-item href="https://www.nrcan.gc.ca/sites/www.nrcan.gc.ca/files/earthsciences/pdf/assess/2016/14-0801-MCR-Access_Apr7_en.pdf">Managing Climate Risks: Highlights for Business Leaders </learn-more-item>
          <learn-more-item href="https://www.bccic.ca/wp-content/uploads/2020/08/Climate-Change-Guide-Full-Version.pdf">Climate Change How-to Guide for Industry and Professional Associations </learn-more-item>
        </learn-more>
      </div>
      <div v-observe-visibility="{ callback: handleActions, throttle: 500 }">
        <a ref="actions" name="actions"/>
        <h2>Actions</h2>
        <h3>Creating a climate-friendly economy</h3>
        <p class="teaser">It’s about keeping the planet livable. Can we do it? Can we afford not to do it?</p>
        <img src="@/assets/images/impacts/sun_mine.jpg"/>

        <p>Making the full transition to a climate-friendly lower emission economy will be several decades in the making and it’s vital that we start now if we are to achieve the reductions in greenhouse gas emissions needed to avoid catastrophic climate changes.</p>
        <p>Many studies have shown that companies aiming for major reductions in emissions, waste generation and resource use are financially outperforming their competitors. Adopting circular economy strategies can result in big cuts to global greenhouse gas emissions and virgin resource use.</p>

        <learn-more>
          <learn-more-item href="https://climatechoices.ca/reports/tip-of-the-iceberg/">Tip of the Iceberg: Navigating the Known and Unknown Costs of Climate Change in Canada </learn-more-item>
          <learn-more-item href="https://www.weforum.org/agenda/2017/03/how-going-green-can-help-the-planet-and-your-profits/">How going green can help the planet and your profits</learn-more-item>
          <learn-more-item href="https://www.communityenergy.ca/resources-publications/">Community Energy Association resources on transition to a lower emission economy</learn-more-item>
          <learn-more-item href="https://www.canada.ca/en/services/environment/conservation/sustainability/circular-economy.html">Circular Economy </learn-more-item>
          <learn-more-item href="https://www2.gov.bc.ca/gov/content/environment/climate-change/adaptation/cpas#economy">BC Climate Preparedness and Adaptation Strategy </learn-more-item>
        </learn-more>

        <h3>Becoming a climate-resilient business</h3>
        <p class="teaser">Our world is more connected than ever before. What does that mean for business?</p>

        <img src="@/assets/images/impacts/logging-truck.jpg" alt="Logging Truck">

        <p>As a business owner, it’s a good idea to be thinking about the ways in which climate change could impact your enterprise.</p>
        <p>Consider:
          <ul>
            <li>How is your business vulnerable to climate-related impacts and what can you do about it?</li>
            <li>What steps can you take to reduce emissions and your business’s impact on climate?</li>
          </ul>
        </p>

        <learn-more>
          <learn-more-item href="https://www.nrcan.gc.ca/sites/www.nrcan.gc.ca/files/earthsciences/pdf/assess/2016/14-0801-MCR-Access_Apr7_en.pdf">Managing Climate Risks: Highlights for Business Leaders </learn-more-item>
          <learn-more-item href="http://nrt-trn.ca/business-primer-facing-the-elements">Building Business Resilience in a Changing Climate</learn-more-item>
          <learn-more-item href="https://200million.ca/">200 Million Tonnes of Opportunity</learn-more-item>
          <learn-more-item href="https://farmersforclimatesolutions.ca/">Farmers for Climate Solutions</learn-more-item>
        </learn-more>

        <h3>Consumer choices</h3>
        <p class="teaser">Don’t underestimate your purchasing power (and the power of not purchasing).</p>
        <img src="@/assets/images/impacts/grocerystore.jpg" alt="Grocery Store">

        <p>As consumers we have a wide range of opportunities to contribute to climate solutions. The choices we make around food, transportation, clothing, electronics, energy, and housing make a difference when everyone’s actions are added together. Our collective spending power and how we choose to use it sends important signals to businesses large and small.</p>
        <p>It’s important that we learn about the climate consequences of our spending and financial investments.</p>

        <learn-more>
          <learn-more-item href="https://www.nrcan.gc.ca/energy/funding/efficiency/4947">Energy Efficiency Rebate Database</learn-more-item>
          <learn-more-item href="https://beyond.ubc.ca/reduce-carbon-footprint/">Top ways to reduce your carbon footprint: They’re not what you think </learn-more-item>
        </learn-more>

        <h3>Introducing the circular economy</h3>
        <p class="teaser">Climate-friendly by design.</p>
        <img src="@/assets/images/impacts/concept-circle.png" alt="Circular Concept">

        <p>The circular economy is poised to replace our current “make, use, and dispose” economy.</p>
        <p>It’s focused on optimizing the use of natural resources by designing products for longevity, repairability, and easy recovery of materials at end-of-life so that they can be reused and repurposed with a minimum investment of energy. In this way, the carbon footprint of products and services is minimized across their entire lifecycle.</p>
        <p>As supply chains are redesigned to embody a circular economy, benefits can be expected for the economy and environment. </p>

        <learn-more>
          <learn-more-item href="https://www.ellenmacarthurfoundation.org/circular-economy">What is a circular economy?</learn-more-item>
          <learn-more-item href="https://www.youtube.com/watch?v=zCRKvDyyHmI">Rethinking progress: The circular economy (video)</learn-more-item>
          <learn-more-item href="http://www.circulareconomyleaders.ca/">Circular Economy Leadership Coalition</learn-more-item>
        </learn-more>
      </div>
    </article>
  </div>
</template>

<script>
import ImpactMixin from '@/components/ImpactMixin.vue'
import learnMoreItem from '../../components/learnMoreItem.vue'

const meta = {
  title: 'Economic Impacts',
  description: 'Impacts on the Columbia Basin Economy due to a Changing Climate'
}

export default {
  components: { learnMoreItem },
  metaInfo () {
    return {
      title: meta.title,
      meta: [
        { property: 'description', content: meta.description, vmid: 'description' },
        { property: 'og:description', content: meta.description, vmid: 'og:description' },
        { property: 'twitter:title', content: meta.title, vmid: 'twitter:title' },
        { property: 'twitter:description', content: meta.description, vmid: 'twitter:description' }
      ]
    }
  },
  mixins: [ImpactMixin]
}
</script>

<style>
</style>
