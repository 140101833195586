<template>
  <li class="spaced-list" v-observe-visibility="{ callback: handleView, throttle: 500 }">
    <input type="checkbox" :id="'layer_' + this.id" :checked="enabled" @click="handleCheckbox">
    <label :for="'layer_' + this.id"><slot> {{ localName }}</slot></label>
  </li>
</template>

<script>
export default {
  props: {
    id: Number,
    name: String,
    olObject: Object,
    enabledDefault: { type: Boolean, default: false },
    viewEnabledDefault: { type: Boolean, default: false }
  },
  computed: {
    localName () { return this.name || this.$store.state.mapLayers.find(x => x.id === this.id).name },
    mapLayer () { return this.$store.state.mapLayers.find(x => x.id === this.id) },
    enabled () { return this.mapLayer.enabled }
  },
  data () {
    return {
      isVisible: false,
      viewEnabled: this.viewEnabledDefault

    }
  },
  mounted () {
  },
  methods: {
    handleCheckbox () {
      if (this.enabled === true) {
        this.$store.commit('LAYER_VISIBILITY', { id: this.id, state: false })
        this.$root.$emit('map/layer-disable', this.id)
        // this.$store.commit('SHOW_MAP_LAYER_ICON', false)
/////  this would make the grey bar disapear when the checkbox is unchecked

      } else {
        this.$store.commit('LAYER_VISIBILITY', { id: this.id, state: true })
        this.$root.$emit('map/layer-enable', this.id)
        this.$store.commit('SHOW_MAP_LAYER_ICON', true)

      }
    },
    handleView (isVisible/*, entry*/) {
      this.isVisisble = isVisible

      if (isVisible && this.viewEnabled && !this.enabled) {
        this.$store.commit('LAYER_VISIBILITY', { id: this.id, state: true })
        this.$store.commit('LAYER_ENABLED_BY_VIEW', { id: this.id, state: true })
        this.$root.$emit('map/layer-enable', this.id)
        this.$store.commit('SHOW_MAP_LAYER_ICON', true)

      } else if (!isVisible && this.viewEnabled && this.enabled && this.mapLayer.enabledByView) {
        this.$store.commit('LAYER_VISIBILITY', { id: this.id, state: false })
        this.$store.commit('LAYER_ENABLED_BY_VIEW', { id: this.id, state: false })
        this.$root.$emit('map/layer-disable', this.id)
        // this.$store.commit('SHOW_MAP_LAYER_ICON', false)
/////  this would make the grey bar disapear when the screen is no longer on a data section


      }
    }
  }
}
</script>

<style></style>
