<template>
  <div class="learn-more">
    <div class="text-white p-2 flex  cursor-pointer" @click="toggleOpened">
      <div class="flex-1 leading-none uppercase text-xl font-condensed">Learn More</div>
      <div class="learn-more-icon" :class="{ 'learn-more-icon-open': opened }"></div>
    </div>
    <div class=" bg-grey" v-show-slide="opened" >
      <ul class="p-2">
        <slot/>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      opened: false
    }
  },
  methods: {
    toggleOpened () {
      this.opened = !this.opened
    }
  }
}
</script>

<style>
.learn-more-icon {
  height: 20px;
  width: 20px;
  position: relative;
  margin-left: auto;
  transition: all ease-in-out 0.2s;
}

.learn-more-icon::after,
.learn-more-icon::before {
  background-color: #eee;
  content: '';
  height: 3px;
  position: absolute;
  top: 5px;
  transition: all ease-in-out 0.13333s;
  width: 15px;
}

.learn-more-icon::before {
  left: 1px;
  transform: rotate(45deg) translate3d(3px, 10px, 0);
  transform-origin: 100%;
}

.learn-more-icon::after {
  transform: rotate(-45deg) translate3d(-3px, 10px, 0);
  right: 1px;
  transform-origin: 0;
}

.learn-more-icon-open::before {
  transform: rotate(45deg) translate3d(6px, 7px, 0);
}

.learn-more-icon-open::after {
  transform: rotate(-45deg) translate3d(-6px, 7px, 0);
}
</style>
