import { render, staticRenderFns } from "./Impacts.vue?vue&type=template&id=0e024957&scoped=true&"
import script from "./Impacts.vue?vue&type=script&lang=js&"
export * from "./Impacts.vue?vue&type=script&lang=js&"
import style0 from "./Impacts.vue?vue&type=style&index=0&id=0e024957&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0e024957",
  null
  
)

export default component.exports