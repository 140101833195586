<template>
  <a :href="this.link" class="image-item" :style="{backgroundImage: 'url(' + imageUrl + ')'}">
    <a :href="this.link" target="_blank" class="bg-green p-2">
      <div class="image-title">{{ title }}</div>
      <div class="image-description">{{ caption }}</div>
    </a>
  </a>
</template>

<script>
export default {
  props: [
    'title',
    'caption',
    'image',
    'link'
  ],
  computed: {
    imageUrl: function () {
      return require('@/assets/images/' + this.image)
    }
  }
}
</script>

<style lang="scss" scoped>
.image-item {
  flex-basis: 200px;
  align-items: flex-end;
  display: flex;
  height: 200px;
  margin: 0.2rem;
  margin: 0.8rem 0 0.8rem 0;
  overflow: hidden;
  background-position: center;
  background-size: cover;
  text-decoration: none;
  color: white;

  a {
    color: white;
    text-decoration: none;
    width: 100%;
  }

  &:hover {
    text-decoration: underline;
  }

  .image-title {
    // text-shadow: 1px 1px 2px #143117;
    width: 100%;
  }
}

.image-description {
  display: none;
}

.image-item:hover {
  // background-color: #278639;
  // background-blend-mode: multiply;

  .image-title {
    display: none;
  }
  .image-description {
    display: block;
  }
}
</style>
