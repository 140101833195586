<template>
  <div>
    <div class="text-center pb-3">
      <img src="@/assets/images/impacts/housing.png">
    </div>
    <div class="nav-sticky-wraper bg-grey">
      <div class="text-center flex items-center">
        <span>
        <h1>
          <router-link class="no-underline text-grey-darker align-middle text-lg" :to="this.previous.url"> ◀ </router-link>
        </h1>
        </span>
        <h1>
          Homes, Buildings and Infrastructure
        </h1>
        <span>
          <h1>
            <router-link class="no-underline text-grey-darker align-middle text-lg" :to="this.next.url"> ▶ </router-link>
          </h1>
        </span>
      </div>
      <div class="section-navigation">
        <div><a class="cursor-pointer" @click="$refs.introduction.scrollIntoView()">Introduction</a></div>
        <div><a class="cursor-pointer" @click="$refs.challenges.scrollIntoView()">Challenges</a></div>
        <div><a class="cursor-pointer" @click="$refs.actions.scrollIntoView()">Actions</a></div>
      </div>
    </div>
    <article>
      <div>
        <a ref="introduction" name="introduction"/>
        <h2>Introduction</h2>

        <h3>Anticipating New Climate Realities</h3>
        <p class="teaser">Floods, droughts, wildfires, and storms: Our changing climate will affect the built environment.</p>

        <image-caption class="border-solid border-green border-2"
          :image="'impacts/house-damage.jpg'"
          title="Case Study"
          caption="Grand Forks Flooding"
          link="/case-studies/CaseStudy_GrandForksFlooding.pdf" />

        <p>Most of our existing buildings and infrastructure were designed and built on the basis of historic weather patterns, which we know are changing. Climate change will bring environmental impacts that could physically threaten our built environment or significantly challenge its ability to serve the needs of residents and businesses. What’s more, uncertainty about the exact nature and extent of future climate changes and environmental impacts is making it harder for architects or engineers to confidently design systems that will last for their intended lifespan.</p>
        <p>In the Columbia Basin, the four top climate challenges our communities are expecting are flooding, drought, wildfire, and severe storms.</p>

        <learn-more>
          <learn-more-item href="https://retooling.ca/">Retooling for Climate Change </learn-more-item>
        </learn-more>
      </div>

      <div>
        <a ref="challenges" name="challenges"/>
        <h2>Challenges</h2>
        <h3>Flooding</h3>
        <p class="teaser">What goes up must come down. With every degree of warming, the earth’s atmosphere holds more water, increasing the risk of more intense rain and snow events.</p>

        <img src="@/assets/images/impacts/sandbags-flood.jpg" alt="Sandbags">

        <p>Around the world, flood events have been increasing in both frequency and intensity.</p>
        <p>Looking to the future, this region can expect more frequent intense rainstorms, increased glacier melt, and more rain falling on frozen ground or snow, increasing the risk of flooding. The potential for landslides also increases when sloped areas get saturated by heavy rainfall.</p>
        <p>Rural areas and small urban centres can be devastated in a flood. This can include loss or damage to homes and businesses, contaminated wells, flooded septic systems, and washouts on roads and bridges. Rebuilding may not always be an option because of future flood risk. These events also cause short- and longer-term social and mental health impacts.</p>

        <learn-more>
          <learn-more-item href="https://www2.gov.bc.ca/gov/content/environment/air-land-water/water/drought-flooding-dikes-dams/river-forecast-centre">BC River Forecast Centre</learn-more-item>
          <learn-more-item href="https://www2.gov.bc.ca/gov/content/safety/emergency-preparedness-response-recovery/preparedbc/know-your-hazards/floods">BC Flood Preparedness for Homeowners</learn-more-item>
          <learn-more-item href="https://www2.gov.bc.ca/gov/content/safety/emergency-management/preparedbc/know-your-hazards/landslides">C Landslide Preparedness for Homeowners </learn-more-item>
          <learn-more-item href="https://www2.gov.bc.ca/gov/content/environment/climate-change/adaptation/resources/bc-adapts/bc-adapts-rainwater">C Adapts – Rainwater Management </learn-more-item>
        </learn-more>

        <h3>Drought</h3>
        <p class="teaser">Trends and projections show that future summers will be hotter and drier.</p>

        <img src="@/assets/images/impacts/drought-plants.jpg" alt="Drought stricken plants">

        <p>A low snowpack, long periods of hot and dry weather, and a delay in seasonal rainfall can all lead to drought.</p>
        <p>Drought impacts the water supply available for homes and businesses, recreation, and for growing food and raising livestock. Lower streamflows may cause warmer water temperatures, which affect fish and other aquatic life. Reduced streamflows may impact groundwater levels. Drought also increases the risk of wildfire.</p>
        <p>Check out the map to view drought conditions in our region over the past 5 years. </p>

        <ul class="layer-list">
          <map-layer :id="8" :viewEnabledDefault="true"/>
        </ul>

        <learn-more>
          <learn-more-item href="https://governmentofbc.maps.arcgis.com/apps/MapSeries/index.html?appid=838d533d8062411c820eef50b08f7ebc">BC Drought Portal</learn-more-item>
          <learn-more-item href="https://www2.gov.bc.ca/assets/gov/environment/air-land-water/water/drought-info/drought_response_plan_final.pdf">BC Drought Response Plan</learn-more-item>
        </learn-more>

        <h3>Wildfire</h3>
        <p class="teaser">Record-breaking BC wildfire seasons like 2018 will become more common in the future.</p>

        <img src="@/assets/images/impacts/fire.jpg" alt="Wildfire">

        <p>When conditions are hot and dry, a spark is far more likely to cause a fire. Lightning is, on average, responsible for about two thirds of BC fires, and warming temperatures increase the probability of lightning.</p>
        <p>Many Canadian studies agree that wildfires are predicted to increase in size and frequency along with climate change.</p>
        <p>Wildfires can have devastating consequences:</p>
        <ul>
          <li>Evacuation orders</li>
          <li>Smoke and air quality issues</li>
          <li>Economic disruption</li>
          <li>Loss of tourism / visitors</li>
          <li>Damage/loss of homes, buildings, and community infrastructure</li>
          <li>Increased erosion and sedimentation of community water sources</li>
          <li>Displaced wildlife</li>
          <li>Mental health impacts (anxiety, stress, trauma, depression)</li>
        </ul>
        <p>Check out the map to view a history of wildfire in our region.</p>


        <ul class="layer-list">
          <map-layer :id="9" :viewEnabledDefault="true"/>
        </ul>

        <learn-more>
          <learn-more-item href="https://www2.gov.bc.ca/assets/gov/public-safety-and-emergency-services/emergency-preparedness-response-recovery/embc/bc-flood-and-wildfire-review-addressing-the-new-normal-21st-century-disaster-management-in-bc-web.pdf">Addressing the new normal: 21st Century Disaster Management in BC</learn-more-item>
          <learn-more-item href="https://www2.gov.bc.ca/gov/content/safety/emergency-preparedness-response-recovery/preparedbc/know-your-hazards/wildfires">BC Wildfire Preparedness Information</learn-more-item>
          <learn-more-item href="https://www.youtube.com/watch?v=V8DqzogXcVg&t=3s">How to set up a clean room in your home </learn-more-item>
        </learn-more>

        <h3>Severe Storms</h3>
        <p class="teaser">Climate change loads the dice for bigger storms.</p>

        <img src="@/assets/images/impacts/hail.jpg" alt="Hail">

        <p>Changing atmospheric circulation patterns can cause more severe storms in some regions and drought in others.</p>
        <p>The Basin may experience these changes as an increased risk of extreme rainstorms, snowstorms, windstorms, and freezing rain events.</p>
        <p>Severe rain, snowfall and freezing rain events pose risks to human safety, property, and local infrastructure, and can disrupt local utilities, services, and businesses.</p>
        <p>Every severe storm event will trigger a unique chain of environmental, economic, structural, and social impacts.</p>
        <p>Insurance industry data confirms that extreme weather events in North America occurred seven times more often in the last decade than from 1961 to 1990.</p>
        <p>Check out the map to see how rainfall is expected to become more extreme in our region.</p>

        <ul class="layer-list">
          <map-layer :id="10" :viewEnabledDefault="true"/>
        </ul>

        <learn-more>
          <learn-more-item href="https://www2.gov.bc.ca/gov/content/safety/emergency-preparedness-response-recovery/preparedbc/know-your-hazards/severe-weather">BC Severe Weather Preparedness Information</learn-more-item>
          <learn-more-item href="http://actuariesclimateindex.org/home/">Actuaries Climate Index</learn-more-item>
        </learn-more>
      </div>
      <div v-observe-visibility="{ callback: handleActions, throttle: 500 }">
        <a ref="actions" name="actions"/>
        <h2>Actions</h2>
        <h3>Adapting Infrastructure and Policies</h3>
        <p class="teaser">Acting to reduce flood risk and protect water supply</p>

        <img src="@/assets/images/impacts/pipes.jpg" alt="Water Pipes">

        <p>Managers of our publicly funded infrastructure (including roadways, water systems and stormwater management systems) are taking steps to evaluate vulnerability to projected climate changes, and take action to address identified priorities. For example, the City of Kimberley redeveloped and naturalized a portion of Mark Creek to withstand a 200-year flood event. The Village of Kaslo improved its water security by repairing leaks in its water system that resulted in system-wide demand reductions of almost 40%. Check out the Actions page for more detail on these initiatives and many more.</p>
        <p>Many Basin communities are also addressing climate change in their Official Community Plans and have updated bylaws and policies with new standards and practices that reflect the challenges of a changing climate.</p>

        <learn-more>
          <learn-more-item href="https://pievc.ca/">Engineers Canada - Public Infrastructure Engineering Vulnerability Committee (PIEVC)</learn-more-item>
          <learn-more-item href="https://fcm.ca/sites/default/files/documents/programs/mcip/guide-for-integrating-climate-change-considerations-into-municipal-am.pdf">Guidance for Integrating Climate Change into Municipal Asset Management </learn-more-item>
          <learn-more-item href="https://ourtrust.org/wp-content/uploads/downloads/2015-11_Trust_ClimateResilience_OCPResourceGuide.pdf">Official Community Plan Policies Supporting Climate-resilience</learn-more-item>
          <learn-more-item href="https://www.ccme.ca/files/Resources/climate_change/Natural_Infrastructure_Report_EN.pdf">Best Practices and Resources on Climate Resilient Natural Infrastructure</learn-more-item>
          <learn-more-item href="https://ourtrust.org/wp-content/uploads/downloads/2016-12_WaterSmart_Summary_FINAL.pdf">The Columbia Basin Water Smart Initiative</learn-more-item>
          <learn-more-item href="https://engage.gov.bc.ca/climatereadybc/">BC Climate Preparedness and Adaptation Strategy </learn-more-item>
          <learn-more-item href="http://www.cbrdi.ca/sites/default/files/Documents/PDF/Climate%20Adaptation/KB_NaturalAssetManagement_Final.pdf">Primer on Natural Asset Management </learn-more-item>
        </learn-more>

        <h3>Emergency Preparedness</h3>
        <p class="teaser">Planning ahead can save life, limb and property in an emergency.</p>

        <img src="@/assets/images/impacts/survival_kit.jpg" alt="Emergency Kit">

        <p>The growing risk of wildfire, severe storms and flooding means that home, neighbourhood, business and community emergency preparedness and response is becoming even more important. The province’s 2017 Flood and Wildfire Review made 108 recommendations to strengthen emergency prevention, planning, preparedness, and response.</p>
        <p>Check out the links below for guidance on how to prepare for emergencies.  Have a look at the Actions page to see what Basin communities are doing to prepare.</p>

        <learn-more>
          <learn-more-item href="https://www2.gov.bc.ca/gov/content/safety/emergency-preparedness-response-recovery/preparedbc">Prepared BC</learn-more-item>
          <learn-more-item href="https://www2.gov.bc.ca/gov/content/safety/emergency-preparedness-response-recovery/preparedbc/build-an-emergency-kit-and-grab-and-go-bag">Build an Emergency Kit</learn-more-item>
          <learn-more-item href="https://www2.gov.bc.ca/assets/gov/public-safety-and-emergency-services/emergency-preparedness-response-recovery/embc/preparedbc/preparedbc-guides/preparedbc_household_guide_2018.pdf">Prepared BC Household Preparedness Guide</learn-more-item>
        </learn-more>

        <h3>Fire Smart and Wildfire Protection</h3>
        <p class="teaser">Learn about the measures to reduce community and household wildfire risk.</p>

        <img src="@/assets/images/impacts/man_chainsaw.jpg" alt="Man with Chainsaw">

        <p>Wildfire protection efforts need to happen at both the community and household scales.</p>
        <p>Many Basin local governments have developed Community Wildfire Protection Plans, which provide a roadmap for reducing wildfire risk and protecting infrastructure in their community.</p>
        <p>A growing number of neighbourhoods and communities are achieving FireSmart designation, meaning that they have worked with an interface fire specialist to plan and implement solutions to fire risk in their area. Check out the Actions map for more details.</p>

        <learn-more>
          <learn-more-item href="https://www.firesmartcanada.ca/resources-library/protecting-your-community-from-wildfire">FireSmart</learn-more-item>
          <learn-more-item href="https://firesmartbc.ca/resource-types/guides-manuals/">FireSmart your Property</learn-more-item>
        </learn-more>

        <h3>Shifting Behaviours</h3>
        <p class="teaser">Becoming climate-resilient demands that we think and do things differently.</p>

        <img src="@/assets/images/impacts/dripping_faucet.jpg" alt="Dripping Faucet">

        <p>As the ultimate consumers of infrastructure and emergency preparedness services, it is up to residents and businesses to do their part. Water conservation is one way that consumers and governments can work together to improve security of water supplies in the face of climate change. </p>
        <p>From 2009 to 2016, <a href="https://ourtrust.org/wp-content/uploads/downloads/2016-12_WaterSmart_Summary_FINAL.pdf">Columbia Basin Trust’s Water Smart Initiative</a> was a catalyst for community-level water conservation efforts, including effective watering strategies for lawns and gardens with residents, and for municipal fields and parks with local governments.</p>

        <learn-more>
          <learn-more-item href="https://ourtrust.org/wp-content/uploads/downloads/2016-12_WaterSmart_Summary_FINAL.pdf">Columbia Basin Water Smart Initiative</learn-more-item>
          <learn-more-item href="https://www.bchydro.com/news/conservation/2018/9-ways-to-conserve-water.html">9 Ways To Conserve Water</learn-more-item>
        </learn-more>

        <h3>Reducing Greenhouse Gas Emissions</h3>
        <p class="teaser">The less we emit, the less we will have to adapt.</p>
        
        <img src="@/assets/images/impacts/solar-installation.jpg" alt="Solar Panel Installation">
        
        <p>How much our climate changes and the severity of future climate impacts will depend on how much and how fast communities and nations around the world can reduce their greenhouse gas emissions. By achieving net zero-emissions by 2050 we can significantly reduce the risk of global ecological collapse. This means taking real steps to reduce emissions at home, at work, in how we travel (and how often), and in our consumer choices. Buildings are the third highest source of greenhouse gas emissions in British Columbia after transportation (number one) and the fossil fuel industry (number two). In today’s growing climate emergency, every reduction matters.</p>
        
        <learn-more>
          <learn-more-item href="https://cleanbc.gov.bc.ca/">Clean BC</learn-more-item>
          <learn-more-item href="https://westkootenayrenewableenergy.ca/">100% Renewable Kootenays</learn-more-item>
          <learn-more-item href="https://betterhomesbc.ca/">Better Homes BC</learn-more-item>
          <learn-more-item href="https://betterbuildingsbc.ca/">Better Buildings BC</learn-more-item>
          <learn-more-item href="https://www.env.gov.bc.ca/soe/indicators/sustainability/ghg-emissions.html">Trends in Greenhouse Gas Emissions in BC</learn-more-item>
          <learn-more-item href="https://www.climateinteractive.org/tools/en-roads/">En-ROADS Climate Change Solutions Simulator</learn-more-item>
        </learn-more>
      </div>

    </article>
  </div>
</template>

<script>
import ImpactMixin from '@/components/ImpactMixin.vue'
import learnMore from '../../components/learnMore.vue'

const meta = {
  title: 'Homes, Buildings, and Infrastructure Impacts',
  description: 'Floods, droughts, wildfire and storms: Our changing climate will affect the built environment.'
}

export default {
  components: { learnMore },
  metaInfo () {
    return {
      title: meta.title,
      meta: [
        { property: 'description', content: meta.description, vmid: 'description' },
        { property: 'og:description', content: meta.description, vmid: 'og:description' },
        { property: 'twitter:title', content: meta.title, vmid: 'twitter:title' },
        { property: 'twitter:description', content: meta.description, vmid: 'twitter:description' }
      ]
    }
  },
  mixins: [ImpactMixin]
}
</script>

<style>
</style>
