<template>
  <div>
    <div class="text-center pb-3">
      <img src="@/assets/images/impacts/nature.png">
    </div>
    <div class="nav-sticky-wraper bg-grey">
      <div class="text-center">
        <h1>
          <router-link class="no-underline text-grey-darker align-middle text-lg" :to="this.previous.url"> ◀ </router-link>
          Nature
          <router-link class="no-underline text-grey-darker align-middle text-lg" :to="this.next.url"> ▶ </router-link>
        </h1>
      </div>
      <div class="section-navigation">
        <div><a class="cursor-pointer" @click="$refs.introduction.scrollIntoView()">Introduction</a></div>
        <div><a class="cursor-pointer" @click="$refs.challenges.scrollIntoView()">Challenges</a></div>
        <div><a class="cursor-pointer" @click="$refs.actions.scrollIntoView()">Actions</a></div>
      </div>
    </div>
    <article>
      <div>
        <a ref="introduction" name="introduction"/>
        <h2>Introduction</h2>

        <h3>Nature is changing, too</h3>
        <p class="teaser">Our surrounding natural environments will see predictable and unpredictable shifts in response to climate change</p>

        <image-caption class="border-solid border-green border-2"
          :image="'impacts/waterlevels_casestudy_rannschild.jpg'"
          title="Case Study"
          caption="Bummers Flats-Cherry Creek Wetland Restoration"
          link="/case-studies/CaseStudy_BummersWetland.pdf" />

        <!-- <img class="border-solid border-green border-2" src="@/assets/images/impacts/waterlevels_casestudy_rannschild.jpg" /> -->

        <p>Natural ecosystems play an irreplaceable role in buffering the impacts of extreme climate events. And, like us, they perform best when there aren't other stressors in the picture.</p>
        <p>There is strong scientific evidence that climate change will significantly affect B.C.'s ecosystems. As temperature and precipitation patterns continue to chart "new normals", ecosystems in the Basin will increasingly undergo changes in the existing composition of plant and animal species, experience more natural disturbances such as wildfire and pests, and changes to water cycles and water availability.</p>
        <p>Not all species will be able to adapt or migrate quickly enough to keep pace with a shifting climate, resulting in additional species at risk and losing some species entirely. The arrival and expansion of new species can also be expected.</p>


        <learn-more>
          <learn-more-item href="https://kootenayconservation.ca/wp-content/uploads/2020/05/Bonanza-Creston-Climate-Adaptation-Strategies-Report.pdf">Climate Disruption and Connectivity in the West Kootenays</learn-more-item>
          <learn-more-item href="https://www2.gov.bc.ca/assets/gov/environment/climate-change/adaptation/prelim-strat-climate-risk-assessment.pdf">Preliminary Strategic Climate Risk Assessment for British Columbia</learn-more-item>
        </learn-more>
      </div>

      <div>
        <a ref="challenges" name="challenges"/>
        <h2>Challenges</h2>
        <h3>Lands and forests under pressure</h3>
        <p class="teaser">Future projections showing shrinking alpine ecosystems and climates that are more suitable for open dry forests and grasslands at valley bottoms</p>
        <img src="@/assets/images/impacts/trees-after-forest-fire-kootenaynpark.jpg"/>
        <p>The most significant changes to BC&#39;s forests are expected to result from more frequent and severe natural disturbances like wildfire and pest outbreaks.</p>
        <p>Hotter drier summers will create challenges for tree regeneration, especially in more southern low elevation areas. Drought stress will also make trees more vulnerable to a wider range of insects, disease, and high winds.</p>
        <p>The expansion of invasive plant species also threatens to disrupt the natural adaptiveness of current ecosystems.</p>
        <p>Extreme precipitation events will increase the risk of flooding and landslides, especially in areas where droughts and wildfire have impacted the soil&#39;s capacity to hold moisture.</p>
        <p>Use the slider on the map to explore how biologists predict Basin ecosystems will change under different future climate scenarios. This map includes current and projected bioclimate envelopes (ecosystem types), along with an indicator of uncertainty. The modeling process that projects future bioclimate envelopes selects the most similar option from a set of existing western North American bioclimate envelopes. However, due to species&#39; migration limitations and the potential for invasive species presence, future ecosystems will likely not be identical to the projected examples. The &#39;High Uncertainty&#39; indicator tells us if it&#39;s likely that the future bioclimate in a given location will produce a new or novel ecosystem type. For more information on bioclimate definitions, future climate scenarios, and the modeling process, click <a href="https://basinclimatesource.ca/reports/Ecosystem%20and%20Tree%20Species%20Bioclimate%20Envelope%20Modeling%20for%20the%20West%20Kootenays_GUtzig_2012.pdf">here</a>.</p>


        <ul class="layer-list">
          <map-layer :id="3" :viewEnabledDefault="true"/>
        </ul>

        <learn-more>
          <learn-more-item href="http://cfcg.forestry.ubc.ca/projects/climate-data/climatebc-and-bioclimatic-envelope-modelling/">ClimateBC and bioclimate envelope modeling</learn-more-item>
          <learn-more-item href="http://www.kootenayresilience.org/">Kootenay Resilience</learn-more-item>
          <learn-more-item href="https://kootenayconservation.ca/conservation-resources/">Conservation resources for the Kootenay region</learn-more-item>
        </learn-more>

        <h3>Shifting Water Cycles</h3>
        <p class="teaser">From floods to drought and everything in between</p>
        <img src="@/assets/images/impacts/bugaboo-spires.jpg"/>
        <p>
          Smaller snowpacks, melting glaciers, less summer precipitation and higher temperatures suggest a future of lower summer flows in Basin rivers and streams, warmer surface water temperatures, and a greater likelihood of drought and changes to groundwater levels.
        </p>

        <p>
          Warmer wetter winters and more precipitation falling as rain at lower elevations could result in more frequent and spiky peak flows, as well as winter overland flooding from rain-on-frozen-ground and rain-on-snow events. Flood events may grow in magnitude and/or frequency.
        </p>

        <p>
          Disturbances on land—like wildfire or deforestation—can reduce the soil’s capacity to buffer rainfall, which raises the likelihood of extreme rain events causing further disruption, e.g. flooding, mass movements, and erosion.
        </p>

        <p>
          Use the time slider on the map to explore how the extent of Basin glaciers has changed in recent history.
        </p>

        <ul class="layer-list">
          <map-layer :id="1" :viewEnabledDefault="true"/>
        </ul>

        <learn-more>
          <learn-more-item href="https://ourtrust.org/wp-content/uploads/downloads/WaterMonitoringandClimateChange_FullReport_2017_FINAL_Web-5.pdf">Water Monitoring and Climate Change in the Upper Columbia Basin Summary of Current Status and Opportunities</learn-more-item>
          <learn-more-item href="https://www2.gov.bc.ca/gov/content/environment/air-land-water/water/drought-flooding-dikes-dams/river-forecast-centre">B.C. River Forecast Centre</learn-more-item>
          <learn-more-item href="https://governmentofbc.maps.arcgis.com/apps/MapSeries/index.html?appid=838d533d8062411c820eef50b08f7ebc">BC Drought Information Portal</learn-more-item>
          <learn-more-item href="https://ourtrust.org/wp-content/uploads/downloads/2020-03_Glaciers-Canadian-ColumbiaBasin-Technical-Full-Report_FINAL.pdf">Glaciers in the Canadian Columbia Basin</learn-more-item>
        </learn-more>

        <h3>Fish and Wildlife: Winners and Losers</h3>
        <p class="teaser">Climate shapes ecological communities, habitats, and which species are more likely to thrive</p>
        <img src="@/assets/images/impacts/kokanee.jpg"/>
        <p>Most fish and wildlife species will experience one of four climate change scenarios: they will either adapt to the new conditions, move elsewhere, be displaced by other species, or die out. At greatest risk are those species already under pressure due to low populations and habitat fragmentation, as well as species with low reproductive rates.</p>

        <p>Use the map to explore the current extent of species at risk.</p>

        <ul class="layer-list">
          <map-layer :id="2" :viewEnabledDefault="true"/>
        </ul>

        <learn-more>
          <learn-more-item href="https://www.youtube.com/watch?v=u4DQrnWjet4">Climate Change and the Biodiversity Crisis</learn-more-item>
          <learn-more-item href="https://www2.gov.bc.ca/assets/gov/environment/natural-resource-stewardship/nrs-climate-change/adaptation/climate20change20vulnerability20of20bcs20fish20and20wildlife20final20june6.pdf">Climate Change Vulnerability of BC's Fish and Wildlife: First Approximation</learn-more-item>
        </learn-more>
      </div>

      <div v-observe-visibility="{ callback: handleActions, throttle: 500 }">
        <a ref="actions" name="actions"/>
        <h2>Actions</h2>
        <h3>Ecosystem Restoration</h3>
        <p class="teaser">Helping ecosystems be resilient in a changing climate</p>
        <img src="@/assets/images/impacts/ecosystem_restoration.jpg"/>
        <p>Ecosystem restoration projects support the recovery of ecosystems that have been degraded, damaged, or destroyed. By taking into account both the challenges and opportunities of climate change, these projects have the potential to help ecosystems adapt and be more resilient to the emerging climate.</p>

        <p> Check out some of the projects featured on the map. </p>

        <learn-more>
          <learn-more-item href="http://fwcp.ca/region/columbia-region/">BC Fish and Wildlife Compensation Program – Current Projects</learn-more-item>
          <learn-more-item href="https://www.trench-er.com/">Rocky Mountain Trench Ecosystem Restoration Program</learn-more-item>
          <learn-more-item href="https://ourtrust.org/grants-and-programs-directory/ecosystem-enhancement-program/">Columbia Basin Trust Ecosystem Enhancement Program</learn-more-item>
        </learn-more>

        <h3>Conservation and Protection</h3>
        <p class="teaser">Taking steps to preserve nature’s riches and wonder for future generations</p>
        <img src="@/assets/images/impacts/emerald-lake.jpg"/>
        <p>
          Climate conservation is about managing and protecting species, their habitats and natural biodiversity in a changing climate. This includes considering how species and habitats will respond to climate change, and where current species and habitats could move in response to future climate conditions. Relocation and assisted migration may benefit some species.
        </p>

        <p>
          Parks and protected areas are critical for conserving biodiversity and connecting landscapes, but the action doesn’t stop there.
        </p>

        <p>
          Natural resource managers, land use planners and even residents can also play valuable roles in protecting critical habitat and wildlife movement corridors on all other public and private lands.
        </p>

        <p>
          Take the time to learn more about conservation and protection initiatives in your area.
        </p>

        <learn-more>
          <learn-more-item href="https://ourtrust.org/wp-content/uploads/downloads/2020_Trust_Adapting-Tree-Management-To-Climate-Change_WEB-3.pdf">Adapting Tree Management to Climate Change</learn-more-item>
          <learn-more-item href="http://biodiversityatlas.org/index.html">Columbia Basin Biodiversity Atlas</learn-more-item>
          <learn-more-item href="https://www2.gov.bc.ca/gov/content/environment/climate-change/adaptation/cpas#species">BC Climate Preparedness and Adaptation Strategy</learn-more-item>
          <learn-more-item href="https://kootenayconservation.ca/stewardship-solutions/">Kootenay Conservation Stewardship Solutions Toolkit</learn-more-item>
        </learn-more>

        <h3>Environmental Monitoring and Research</h3>
        <p class="teaser">Without access to solid research and data, climate-informed planning and decision-making will be hit and miss</p>
        <img src="@/assets/images/impacts/environmental_monitoring.jpg"/>
        <p>
          Nature as we know it is a complex and sophisticated web of interconnected relationships that have evolved together over many millenia.
        </p>

        <p>
          Given all this complexity, there’s still a lot of uncertainty about how natural systems will respond to the current rate of climate change, and what the best management approaches will be to help nature (and humans) survive and thrive.
        </p>

        <p>
          Environmental research and monitoring can play an important role in reducing that uncertainty and assisting with making better management decisions.
        </p>
        <p>
          Whether it’s gathering data to better understand our water sources and watersheds, how our forests are responding to changing growing seasons, or how fast a new invasive species is expanding its range, it can help us be better long-term stewards of our natural environment.
        </p>

        <learn-more>
          <learn-more-item href="https://www2.gov.bc.ca/gov/content/environment/research-monitoring-reporting">BC Environment - Research, Monitoring and Reporting</learn-more-item>
          <learn-more-item href="https://ourtrust.org/wp-content/uploads/downloads/WaterMonitoringandClimateChange_FullReport_2017_FINAL_Web-5.pdf">Water Monitoring and Climate Change in the Upper Columbia Basin Summary of Current Status and Opportunities</learn-more-item>
          <learn-more-item href="https://livinglakescanada.ca/our-programs/">Living Lakes Canada monitoring projects</learn-more-item>
        </learn-more>
      </div>

    </article>
  </div>
</template>

<script>
import ImpactMixin from '@/components/ImpactMixin.vue'

const meta = {
  title: 'Nature Impacts',
  description: 'Our surrounding natural environments will see predictable and unpredictable shifts in response to climate change'
}

export default {
  metaInfo () {
    return {
      title: meta.title,
      meta: [
        { property: 'description', content: meta.description, vmid: 'description' },
        { property: 'og:description', content: meta.description, vmid: 'og:description' },
        { property: 'twitter:title', content: meta.title, vmid: 'twitter:title' },
        { property: 'twitter:description', content: meta.description, vmid: 'twitter:description' }
      ]
    }
  },
  mixins: [ImpactMixin]
}
</script>
